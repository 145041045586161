


































import { Row, Col, Icon, Cell, CellGroup } from "vant";

export default {
  components: {
    [Col.name]: Col,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [Row.name]: Row
  }
};
